import React from 'react';

function NotFound() {
    return (
        <div>
           Not found page
        </div>

    );
}

export default NotFound;